/* eslint-disable no-warning-comments */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-syntax */
import { useCallback, useEffect, useState } from 'react';

import { IoStar } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';

import EmblaCarousel from '~/components/Carrousel';
import { ListVideoGroup } from '~/components/ListVideoGroup';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { StudentLayout } from '~/layout';
import { PageOptionsRequest, PageOptionsResponse } from '~/models/Common';
import Courses from '~/models/Courses';
import { Lives, LivesData } from '~/models/Lives';
import { FormattedWorkoutsData, Workouts } from '~/models/Workouts';
import { cleanRequests } from '~/services/api';
import CourseService from '~/services/CourseService';
import LivesService from '~/services/LivesService';
import WorkoutsService from '~/services/WorkoutsService';
import errorHandlerToToast from '~/utils/errorHandler';

import * as S from './styles';

const OPTIONS = {};

interface RouteParam {
  state: {
    professionalId: number;
  };
}

const CourseList: React.FC = () => {
  const location = useLocation() as RouteParam;
  const navigate = useHistory();
  const [banners, setBanners] = useState([]);
  const [lives, setLives] = useState<LivesData>({} as LivesData);
  const [courses, setCourses] = useState<PageOptionsResponse<Courses>>(
    {} as PageOptionsResponse<Courses>,
  );
  const [courseSuggestions, setcourseSuggestions] = useState<
    PageOptionsResponse<Courses>
  >({} as PageOptionsResponse<Courses>);
  const [workouts, setWorkouts] = useState<FormattedWorkoutsData>(
    {} as FormattedWorkoutsData,
  );
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
  });
  const { user } = useAuth();
  const { setLoading } = useLoading();
  const { addToast } = useToast();

  const getStudentCourses = useCallback(async () => {
    try {
      setLoading(true);
      if (user.role === 'student') {
        const response = await CourseService.getStudentCourses({
          studentId: user.student.id,
          perpage: 10,
        });
        setCourses(response.data);
      } else {
        throw new Error('User must be a student');
      }
    } catch (error: any) {
      const responseErr = errorHandlerToToast({
        error,
        description: 'Não foi possível carregar os cursos.',
      });

      if (responseErr) addToast(responseErr);
    } finally {
      setLoading(false);
    }
  }, [addToast, setLoading, user]);

  const getCourseSuggestions = useCallback(async () => {
    try {
      setLoading(true);
      if (user.role === 'student') {
        const response = await CourseService.getSuggestionsCourses({
          perpage: 10,
        });
        setcourseSuggestions(response.data);
      } else {
        throw new Error('User must be a student');
      }
    } catch (error: any) {
      const responseErr = errorHandlerToToast({
        error,
        description: 'Não foi possível carregar as sugestoes de cursos.',
      });

      if (responseErr) addToast(responseErr);
    } finally {
      setLoading(false);
    }
  }, [addToast, setLoading, user]);

  const separateByCategory = (
    data: Workouts[],
  ): {
    label: string;
    courses: Workouts[];
  }[] => {
    const categories: {
      label: string;
      courses: Workouts[];
    }[] = [];
    for (const course of data) {
      const categoryId = course.category.id;
      const categoryDescription = course.category.description;
      if (!categories[categoryId]) {
        categories[categoryId] = {
          label: categoryDescription,
          courses: [],
        };
      }
      categories[categoryId].courses.push(course);
    }
    return categories;
  };

  const getStudentWorkouts = useCallback(async () => {
    try {
      setLoading(true);
      if (user.role === 'student') {
        const response = await WorkoutsService.getStudentWorkoutsByProfessional(
          {
            studentId: user.student.id,
            perpage: 9999,
            professionalId: location.state.professionalId,
          },
        );

        const categories = separateByCategory(response.data.data);

        const formattedData = { ...response.data, data: categories };

        setWorkouts(formattedData);
      } else {
        throw new Error('User must be a student');
      }
    } catch (error: any) {
      const responseErr = errorHandlerToToast({
        error,
        description: 'Não foi possível carregar as aulas gravadas.',
      });

      if (responseErr) addToast(responseErr);
    } finally {
      setLoading(false);
    }
  }, [addToast, setLoading, user]);

  const getWorkoutBanners = useCallback(async () => {
    try {
      setLoading(true);

      if (user.role === 'student') {
        const response = await WorkoutsService.getWorkoutsBanners(
          location.state.professionalId,
        );

        setBanners(response.data.data);
      } else {
        throw new Error('User must be a student');
      }
    } catch (error: any) {
      const responseErr = errorHandlerToToast({
        error,
        description: 'Não foi possível carregar os banners.',
      });

      if (responseErr) addToast(responseErr);
    } finally {
      setLoading(false);
    }
  }, [addToast, location.state.professionalId, setLoading, user]);

  const getStudentLiveWorkouts = useCallback(async () => {
    try {
      setLoading(true);
      if (user.role === 'student') {
        const response = await LivesService.getStudentLives(
          user.student.id,
          pageOptions,
        );

        setLives(response.data);
      } else {
        throw new Error('User must be a student');
      }
    } catch (error: any) {
      const responseErr = errorHandlerToToast({
        error,
        description: 'Não foi possível carregar as aulas ao vivo.',
      });

      if (responseErr) addToast(responseErr);
    } finally {
      setLoading(false);
    }
  }, [addToast, pageOptions, setLoading, user]);

  useEffect(() => {
    getStudentLiveWorkouts();
    return cleanRequests;
  }, [getStudentLiveWorkouts, pageOptions]);

  useEffect(() => {
    getStudentWorkouts();
    return cleanRequests;
  }, [getStudentWorkouts]);

  useEffect(() => {
    getWorkoutBanners();
    return cleanRequests;
  }, [getWorkoutBanners]);

  useEffect(() => {
    getStudentCourses();
    return cleanRequests;
  }, [getStudentCourses]);

  useEffect(() => {
    getCourseSuggestions();
    return cleanRequests;
  }, [getCourseSuggestions]);

  return (
    <StudentLayout>
      <S.Hero>
        <EmblaCarousel
          showSlideContent
          banners={banners.slice(0, 3)}
          options={OPTIONS}
        />
      </S.Hero>

      <S.Container>
        {lives && lives.total > 0 && (
          <ListVideoGroup<Lives>
            title={
              <S.LiveTitleContainer>
                <S.RedDot /> Aulas ao vivo
              </S.LiveTitleContainer>
            }
            data={lives}
            professionalId={location.state.professionalId}
          />
        )}

        {banners && banners.length > 0 && (
          <ListVideoGroup<Lives>
            title={<S.LiveTitleContainer>Aulas recentes</S.LiveTitleContainer>}
            data={{
              total: banners.length,
              data: banners,
              lastPage: 1,
              perPage: 9999,
              page: 1,
            }}
            professionalId={location.state.professionalId}
          />
        )}

        {workouts && workouts.total > 0 && (
          <>
            {workouts.data.map(({ label, courses }) => {
              const formattedValue = {
                ...workouts,
                data: courses,
              };

              return (
                <ListVideoGroup<Workouts>
                  title={label}
                  data={formattedValue}
                  professionalId={location.state.professionalId}
                  categoryId={courses[0].category.id}
                />
              );
            })}
          </>
        )}

        {courseSuggestions && courseSuggestions.total > 0 && (
          <ListVideoGroup<Courses>
            title={
              <S.LiveTitleContainer>
                <IoStar color="#ead88c" size={30} />
                Cursos
              </S.LiveTitleContainer>
            }
            data={courseSuggestions}
            professionalId={location.state.professionalId}
          />
        )}

        {courses && courses.total > 0 && (
          <ListVideoGroup<Courses>
            title={
              <S.LiveTitleContainer>
                <IoStar color="#ead88c" />
                Cursos
              </S.LiveTitleContainer>
            }
            data={courses}
            professionalId={location.state.professionalId}
          />
        )}
      </S.Container>
    </StudentLayout>
  );
};

export default CourseList;
